console.log = function () {};
import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import VueRouter from 'vue-router'
import router from './router'
import 'buefy/dist/buefy.css'
import '@/style/style.scss'
import '@/service/ghostImage'
import VueCarousel from 'vue-carousel';
import VueSocialSharing from 'vue-social-sharing'
import VueMeta from 'vue-meta'
import store from "./store";
import moment from "moment";

import momentTH from 'moment/locale/th'

Vue.use(moment);
Vue.use(VueSocialSharing);
Vue.use(VueMeta)
Vue.use(VueCarousel);
Vue.use(Buefy)
Vue.use(VueRouter)
Vue.config.productionTip = false
var numeral = require("numeral");

Vue.filter("comma", function (value) {
  return numeral(value).format("0,0");
});
Vue.filter('timeTh', (date) => {
  let res = '';
  if (typeof date === 'undefined' || date === null || date === '') {
    res = '-';
  } else {
    res = moment(moment(date).locale('th', momentTH).add(543, 'y')).format('D MMM YYYY');
  }
  return res;
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')