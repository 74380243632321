export default [{
    nameClass: "facebook",
    icon: "fab fa-facebook-f",
    count: 2874,
    way: "fanpage",
    click: "like",
    link: "https://www.facebook.com/avocadoxbyphusit",
  },
  {
    nameClass: "line",
    icon: "fab fa-line",
    count: 941,
    way: "members",
    click: "join",
    link: "https://line.me/ti/p/~@fol4031e",
  },
  {
    nameClass: "soundcloud",
    icon: "fab fa-soundcloud",
    count: 47,
    way: "followers",
    click: "follow",
    link: "https://soundcloud.com/avocado-x-128494759",
  },
  {
    nameClass: "twitter",
    icon: "fab fa-twitter",
    count: 8,
    way: "followers",
    click: "follow",
    link: "https://twitter.com/AvocadoX5",
  },
  {
    nameClass: "youtube",
    icon: "fab fa-youtube",
    count: 567,
    way: "SUBSCRIBERS",
    click: "SUBSCRIBE",
    link: "https://www.youtube.com/channel/UCwJ1XodzBNovsX-h3b5HqAA",
  },
];