import axios from "axios";

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_API,
  timeout: 1000 * 120,
});

export const BLOG = axios.create({
  baseURL: 'https://blog.avocadox.co/ghost/api/v3/content/',
  timeout: 1000 * 120,
});
