<template>
  <div class="box-flex-news">
    <div class="column is-one-third is-one-third-mobile">
      <a :href="`/detail/${news.tags[0].slug.toLowerCase()}/${news.slug}`">
        <b-image
          :src="getGhostImage(news.feature_image, 100)"
          :srcset="getGhostImageSet(news.feature_image, [100, 300])"
          ratio="4by3"
          :alt="news.title"
          class="isLoaded border-img"
          lazy
        ></b-image>
      </a>
    </div>
    <div class="column">
      <a :href="`/detail/${news.tags[0].slug.toLowerCase()}/${news.slug}`" class="sub-title">{{
        news.title
      }}</a>
    </div>
  </div>
</template>
<style lang="scss" scope>
.sub-title {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.border-img {
  img {
    object-fit: cover;
    border-radius: 10px;
  }
}
</style>
<script>
export default {
  props: ["news", "routeName"],
};
</script>
