<template>
  <div id="custom-nav">
    <div v-if="namePages != 'terms-of-use' && namePages != 'privacy-policy'">
      <div class="box-logo">
        <div class="hamberger-mb" @click="openMenu()">
          <b-icon pack="fas" icon="bars"></b-icon>
        </div>
        <a href="/"> <img src="@/image/logo1.png" class="logo" /></a>
        <div class="search-mb" @click="openSearch()">
          <b-icon pack="fas" icon="search"></b-icon>
        </div>
      </div>
      <div id="nav">
        <div class="container">
          <Navbar />
        </div>
      </div>

      <router-view />
      <Footer />
      <Copyright />
      <NavbarMenu
        :open1="dataOpenMenu"
        :right1="wayRightMenu"
        @sendOpen="close"
      />
      <NavbarSearch :open2="dataOpen" :right2="wayRight" @sendOpen="close" />
      <!-- @sendOpen -->
    </div>
    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Copyright from "@/components/Copyright";
import NavbarSearch from "@/components/NavbarSearch";
import NavbarMenu from "@/components/NavbarMenu";
export default {
  name: "App",
  components: {
    Navbar,
    Footer,
    Copyright,
    NavbarSearch,
    NavbarMenu,
  },
  mounted() {
    this.namePage();
  },
  data() {
    return {
      namePages: "",
      dataOpen: false,
      wayRight: false,
      dataOpenMenu: false,
      wayRightMenu: false,
    };
  },
  methods: {
    namePage() {
      var loc = window.location.pathname;
      var dir = loc.replace("/", "");
      var page = dir.split("/");
      this.namePages = page[0];
    },
    openSearch() {
      this.dataOpen = true;
      this.wayRight = true;
    },
    openMenu() {
      this.dataOpenMenu = true;
      this.wayRightMenu = false;
    },
    close(dataOpen) {
      this.dataOpen = dataOpen;
      this.dataOpenMenu = dataOpen;
    },
  },
};
</script>
