import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "*",
    component: () => import("@/views/ErrorScreen")
  },
  {
    path: '/',
    name: 'Home',
    subname: '',
    component: () => import('@/views/Home')
  },
  {
    path: '/category/:category',
    name: 'NewCategory',
    subname: 'newCategory',
    component: () => import('@/views/NewsList')
  },
  //  {
  //    path: '/category/interesting',
  //    name: 'Interesting',
  //    subname: 'interesting',
  //    component: () => import('@/views/NewsList')
  //  },
  // {
  //   path: '/category/media',
  //   name: 'Media',
  //   subname: 'media',
  //   component: () => import('@/views/NewsList')
  // },
  // {
  //   path: '/category/digital',
  //   name: 'Digital',
  //   subname: 'digital',
  //   component: () => import('@/views/NewsList')
  // },
  // {
  //   path: '/category/business',
  //   name: 'Business',
  //   subname: 'business',
  //   component: () => import('@/views/NewsList')
  // },

  {
    path: '/category/:category/:id',
    name: 'Academy',
    subname: 'academy',
    component: () => import('@/views/NewsList')
  },
  // {
  //   path: '/category/media/:id',
  //   name: 'Media',
  //   subname: 'media',
  //   component: () => import('@/views/NewsList')
  // },
  // {
  //   path: '/category/digital/:id',
  //   name: 'Digital',
  //   subname: 'digital',
  //   component: () => import('@/views/NewsList')
  // },
  {
    path: '/detail/:category/:slug',
    name: 'detail',
    subname: 'detail',
    props: (route) => ({
      routeParams: route.query,
    }),
    component: () => import('@/views/NewsDetail')
  },
  {
    path: '/search/:id?',
    name: 'Search',
    subname: 'search',
    component: () => import('@/views/Search')
  },
  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    subname: 'TermsOfUse',
    component: () => import('@/views/TermsOfUse')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    subname: 'PrivacyPolicy',
    component: () => import('@/views/PrivacyPolicy')
  },

]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router