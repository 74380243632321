<template>
  <div>
    <a
      href="/"
      :class="
        clickSearch == true
          ? ''
          : pathName == '/'
          ? 'router-link-exact-active router-link-active'
          : ''
      "
      >Home</a
    >
    <a
      href="/category/business"
      :class="
        clickSearch == true
          ? ''
          : pathName == '/category/business'
          ? 'router-link-exact-active router-link-active'
          : ''
      "
      >Business Contents</a
    >
    <a
      class="slot-btn"
      :class="
        clickSearch == true
          ? ''
          : subCategory == 'academy'
          ? 'add-underline'
          : ''
      "
    >
      <b-dropdown aria-role="list" position="is-bottom-left">
        <button class="button btn-nav-hover" slot="trigger">
          <p>Academy</p>
          <b-icon  pack="fas" icon="angle-down"></b-icon>
        </button>
        <b-dropdown-item aria-role="listitem">
          <a href="/category/academy/online">Online Learning</a>
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem">
          <a href="/category/academy/hybrid">Hybrid Learning </a>
        </b-dropdown-item>
      </b-dropdown>
    </a>
    <a
      class="slot-btn"
      :class="
        clickSearch == true ? '' : subCategory == 'media' ? 'add-underline' : ''
      "
    >
      <b-dropdown aria-role="list">
        <button class="button btn-nav-hover" slot="trigger">
          <p>Media</p>
          <b-icon  pack="fas" icon="angle-down"></b-icon>
        </button>
        <b-dropdown-item aria-role="listitem">
          <a href="/category/media/news">Activity News </a>
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem">
          <a href="/category/media/youtube">Youtube </a>
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem">
          <a href="/category/media/podcast">Podcast </a>
        </b-dropdown-item>
      </b-dropdown>
    </a>
    <a
      class="slot-btn"
      :class="
        clickSearch == true
          ? ''
          : subCategory == 'digital'
          ? 'add-underline'
          : ''
      "
    >
      <b-dropdown aria-role="list">
        <button class="button btn-nav-hover" slot="trigger">
          <p>Digital Platform</p>
          <b-icon  pack="fas" icon="angle-down"></b-icon>
        </button>
        <b-dropdown-item aria-role="listitem">
          <a href="https://navigator.avocadox.co" target="_blank"
            >Business Navigator</a
          >
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem">
          <a href="https://www.yellow-idea.com" target="_blank">Yellow Idea</a>
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem">
          <a href="https://manawork.com" target="_blank">MANA</a>
        </b-dropdown-item>
      </b-dropdown>
    </a>

    <b-dropdown aria-role="list" class="icon-search">
      <button class="button" slot="trigger">
        <b-icon pack="fas" icon="search"></b-icon>
      </button>
      <div class="box-search">
        <b-input v-model="dataSearch" @keyup.13.native="searchBlog"></b-input>
        <button class="btn btn-search" @click="searchBlog()">ค้นหา</button>
      </div>
    </b-dropdown>

    <!-- <b-icon icon="search" size="is-small"></b-icon> -->
  </div>
</template>
<style lang="scss" scope>
.slot-btn {
  .btn-nav-hover {
    padding-top: 10px !important;
    &:hover {
      &::before {
        width: 0 !important;
        background-color: transparent !important;
      }
    }
  }
  &.add-underline {
    position: relative;
    &::before {
      content: "";
      width: 100% !important;
      left: 0 !important;
      transform: none !important;
    }
  }
}
</style>
<script>
import { BLOG } from "@/service/axios.js";
export default {
  name: "Navbar",
  data() {
    return {
      dataSearch: "",
      pathName: "",
      clickSearch: false,
      subCategory: "",
    };
  },
  mounted() {
    this.checkPage();
  },
  methods: {
    searchBlog() {
      this.$router.push(`/search/${this.dataSearch}`);
      this.dataSearch = "";
      this.clickSearch = true;
    },
    checkPage() {
      this.pathName = window.location.pathname;
      var str = this.pathName.split("/");
      if (str.length >= 3) {
        this.subCategory = str[2];
      }
    },
  },
};
</script>
