<template>
  <section>
    <b-sidebar
      class="nav-show-search"
      type="is-light"
      :fullheight="fullheight"
      :fullwidth="fullwidth"
      :overlay="overlay"
      :right="right"
      :open.sync="open1"
    >
      <div class="group-social">
        <b-button class="btn btn-close" @click="closeTab()">
          <i class="fas fa-times"></i>
        </b-button>
        <div class="">
          <span class="box-icon">
            <a href="https://www.facebook.com/avocadoxbyphusit" target="_blank">
              <span class="icon">
                <img src="../assets/icon/facebook.png" class="width-icon" />
              </span>
            </a>
          </span>
           <span class="box-icon">
            <a href="https://line.me/ti/p/~@fol4031e" target="_blank">
              <span class="icon">
                <img src="../assets/icon/line.png" class="width-icon" />
              </span>
            </a>
          </span>
           <span class="box-icon">
            <a href="tel:0818280800">
              <span class="icon">
                <img src="../assets/icon/phone.png" class="width-icon" />
              </span>
            </a>
          </span>
           <span class="box-icon">
            <a href="https://www.youtube.com/channel/UCwJ1XodzBNovsX-h3b5HqAA" target="_blank">
              <span class="icon">
                <img src="../assets/icon/youtube.png" class="width-icon" />
              </span>
            </a>
          </span>
        </div>
      </div>

      <b-menu class="slidemenu">
        <b-menu-list>
          <a href="/">Home</a>
        </b-menu-list>
        <b-menu-list>
          <a href="/category/business">Business Contents</a>
        </b-menu-list>
        <b-menu-list>
          <b-menu-item>
            <template slot="label" slot-scope="props">
              <p>Academy</p>
              <b-icon pack="fas"
                class="is-pulled-right custom-pulled"
                :icon="props.expanded ? 'angle-up' : 'angle-down'"
              ></b-icon>
            </template>
            <b-menu-list>
              <a href="/category/academy/online">Online Learning</a>
            </b-menu-list>
            <b-menu-list>
              <a href="/category/academy/hybrid">Hybrid Learning</a>
            </b-menu-list>
          </b-menu-item>
        </b-menu-list>
        <b-menu-list>
          <b-menu-item>
            <template slot="label" slot-scope="props">
              <p>Media</p>
              <b-icon pack="fas"
                class="is-pulled-right custom-pulled"
                :icon="props.expanded ? 'angle-up' : 'angle-down'"
              ></b-icon>
            </template>
            <b-menu-list>
              <a href="/category/media/news" class="inside">Activity News</a>
            </b-menu-list>
            <b-menu-list>
              <a href="/category/media/youtube" class="inside">Youtube</a>
            </b-menu-list>
            <b-menu-list>
              <a href="/category/media/podcast" class="inside">Podcast</a>
            </b-menu-list>
          </b-menu-item>
        </b-menu-list>
        <b-menu-list>
          <b-menu-item>
            <template slot="label" slot-scope="props">
              <p>Digital Platform</p>
              <b-icon pack="fas"
                class="is-pulled-right custom-pulled"
                :icon="props.expanded ? 'angle-up' : 'angle-down'"
              ></b-icon>
            </template>
            <b-menu-list>
              <a href="https://navigator.avocadox.co" target="_blank">Business Navigator</a>
            </b-menu-list>
            <b-menu-list>
              <a href="https://www.yellow-idea.com" target="_blank">Yellow Digital</a>
            </b-menu-list>
            <b-menu-list>
              <a href="https://manawork.com" target="_blank">MANA</a>
            </b-menu-list>
          </b-menu-item>
        </b-menu-list>
      </b-menu>
    </b-sidebar>
  </section>
</template>
<style lang="scss" scope>
.group-social {
  padding: 20px 15px 40px;
}
</style>
<script>
export default {
  data() {
    return {
      overlay: true,
      fullheight: true,
      fullwidth: true,
      right: false,
    };
  },
  methods: {
    closeTab() {
      this.open = false;
      this.$emit("sendOpen", this.open);
    },
  },
  props: ["open1", "right1"],
};
</script>
