import Vue from 'vue'

Vue.mixin({
  methods: {
    getGhostImageSet(url, sizes) {
      if (url != null) {
        const urlJoin = []
        for (let i = 0; i < sizes.length; i++) {
          const replace = 'content/images'
          const urlSplit = url.replace(replace, replace + '/size/w' + sizes[i])
          urlJoin.push(urlSplit + ' ' + sizes[i] + 'w')
        }
        return urlJoin.join(',')
      } else {
        return null
      }
    },
    getGhostImage(url, size) {
      if (url != null) {
        const replace = 'content/images'
        const urlSplit = url.replace(replace, replace + '/size/w' + size)
        return urlSplit
      } else {
        return null
      }
    },
  },
})
