<template>
  <div id="footer">
    <div class="container">
      <div class="columns">
        <div class="column">
          <div class="footer-info">
            <div class="footer-logo">
              <img src="@/image/logo1.png" class="logo" />
            </div>
            <div class="footer-text">
              AvocadoX ส่งต่อเรื่องราวเกี่ยวกับธุรกิจ การตลาด เทคโนโลยี
              แรงบันดาลใจ และข้อคิดในการใช้ชีวิต
              <br />
              <br />ติดต่อเรา: dr.phusit@gmail.com
            </div>
            <div class="footer-social">
              <span class="social-icon">
                <a
                  href="https://www.facebook.com/avocadoxbyphusit"
                  target="_blank"
                >
                  <span class="facebook">
                    <i class="fab fa-facebook-f"></i>
                  </span>
                </a>
              </span>
               <span class="social-icon">
                <a
                  href="https://line.me/ti/p/~@fol4031e"
                  target="_blank"
                >
                  <span class="line">
                   <i class="fab fa-line"></i>
                  </span>
                </a>
              </span>
              <span class="social-icon">
                <a
                  href="https://soundcloud.com/avocado-x-128494759"
                  target="_blank"
                >
                  <span class="soundcloud">
                    <i class="fab fa-soundcloud"></i>
                  </span>
                </a>
              </span>
              <span class="social-icon">
                <a href="https://twitter.com/AvocadoX5" target="_blank">
                  <span class="twitter">
                    <i class="fab fa-twitter"></i>
                  </span>
                </a>
              </span>
              <span class="social-icon">
                <a
                  href="https://www.youtube.com/channel/UCwJ1XodzBNovsX-h3b5HqAA"
                  target="_blank"
                >
                  <span class="youtube">
                    <i class="fab fa-youtube"></i>
                  </span>
                </a>
              </span>
            </div>
          </div>
        </div>
        <div class="column popular-post">
          <div class="footer-popular">
            <div class="head-popular">POPULAR POSTS</div>
          </div>
          <div
            class="columns set-flex is-0"
            v-for="(data_news, index) in blogPopular"
            :key="index"
          >
            <NewsMiniSite :news="data_news" v-if="loading == false" />
          </div>
        </div>
        <div class="column popular-category">
          <div class="head-popular">CATEGORY</div>
          <div class="widget">
            <ul v-if="load">
              <li v-for="(dataBlog, index) in dataCount.category" :key="index">
                <a :href="`/category/${dataBlog.name}`">
                  <p>
                    <span v-if="dataBlog.name == 'business'"
                      >BUSINESS CONTENTS</span
                    >
                    <span v-else-if="dataBlog.name == 'digital'"
                      >DIGITAL PLATFORM</span
                    >
                    <span v-else>{{ dataBlog.name }}</span>
                  </p>
                  <p>{{ dataBlog.count }}</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NewsMiniSite from "@/components/NewsMiniSite";
import { BLOG } from "@/service/axios.js";
export default {
  data() {
    return {
      blogPopular: [],
      dataCount: [],
      load: false,
      loading: true,
    };
  },
  components: {
    NewsMiniSite,
  },
  async mounted() {
    this.getBlogPopular();
    await this.getCountBlog();
  },
  methods: {
    getBlogPopular() {
      BLOG.get(
        `posts?key=db9af1ab9fa83c98a75d2f98bb&page=1&limit=4b&filter=featured:true&include=tags`
      )
        .then(async (res) => {
          if (res.status == 200) {
            this.blogPopular = res.data.posts;
            this.loading = false;
            console.log("blogPopular", this.blogPopular);
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("get blog error");
        });
    },
    async getCountBlog() {
      var dataCategory = ["Business", "Academy", "Media", "Digital"];
      this.dataCount.category = [];
      for (let a = 0; a < dataCategory.length; a++) {
        await BLOG.get(
          `posts?key=db9af1ab9fa83c98a75d2f98bb&page=1&filter=tag:${dataCategory[a]}`
        )
          .then(async (res) => {
            if (res.status == 200) {
              var blog = {
                name: dataCategory[a].toLowerCase(),
                count: res.data.meta.pagination.total,
              };
              this.dataCount.category.push(blog);
            } else {
              this.alertFailError();
            }
          })
          .catch((e) => {
            this.alertCatchError(e);
            console.log("get blog error");
          });
      }
      this.load = true;
    },
  },
};
</script>