<template>
  <section>
    <b-sidebar
      class="nav-show-search"
      type="is-light"
      :fullheight="fullheight"
      :fullwidth="fullwidth"
      :overlay="overlay"
      :right="right2"
      :open.sync="open2"
    >
      <div class="p-1">
        <b-button class="btn btn-close" @click="closeTab()">
          <i class="fas fa-times"></i>
        </b-button>
        <div class="box-inside-search">
          <h4>ค้นหา</h4>
          <b-input
            class="input-search"
            v-model="dataSearch"
            @keyup.13.native="searchBlog"
          ></b-input>
        </div>
      </div>
    </b-sidebar>
  </section>
</template>
<style lang="scss" scope>
.input-search .input {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  height: 40px;
  line-height: 36px;
  background: transparent;
  outline: 0;
  margin: 8px 0;
  padding: 0;
  text-align: center;
  border: 0px;
  box-shadow: none;
  border-bottom: 1px solid #fff;
  width: 100%;
  &:focus {
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #fff;
  }
}
</style>
<script>
export default {
  data() {
    return {
      overlay: true,
      fullheight: true,
      fullwidth: true,
      right: false,
      dataSearch: "",
    };
  },
  methods: {
    closeTab() {
      this.open = false;
      this.$emit("sendOpen", this.open);
    },
    searchBlog() {
      this.$router.push(`/search/${this.dataSearch}`);
      this.dataSearch = "";
      this.closeTab();
    },
  },
  props: ["open2", "right2"],
};
</script>
